import React from "react";
import { Box } from "@chakra-ui/react";
import privacyPolicy from "../images/terms_and_conditions.pdf";

export default function TermsPage() {
  return (
    <Box h="100vh" w="100vw">
      <embed src={privacyPolicy} height="100%" width="100%" />
    </Box>
  );
}
